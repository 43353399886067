import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import { Container } from "react-bootstrap";

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="Page Not Found" />

        <main role="main" style={{ marginTop: "56px" }}>
          <Container>
            <div style={{ padding: "8rem 0" }}>
              <h1
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  paddingBottom: "1rem",
                }}
              >
                Page Not Found
              </h1>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "6rem",
                  paddingBottom: "1rem",
                }}
              >
                <span role="img" aria-label="Page Not Found">
                  🤷‍♀️
                </span>
              </div>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  // paddingBottom: "1rem",
                }}
              >
                존재하지 않는 페이지입니다.
              </p>
            </div>
          </Container>
        </main>
      </Layout>
    );
  }
}

export default NotFoundPage;
